import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/GlobalProvider";
import LiveGoldPrice from "../../components/Tools/LiveGoldPrice";
import UserCurrency from "../../components/Tools/UserCurrency";
import { TheGoldJar1080p, TheGoldJartitle } from "../../assets/data/Imagedata";
import BottomNavbar from "./BottomNavbar";
import {
  FaQuestionCircle,
  FaBars,
  FaTimes,
  FaChevronDown,
} from "react-icons/fa";
import Notification from "../Tools/Notification";

const Navbar = () => {
  const phoneNumber = "9519519581";
  const message = encodeURIComponent("Hello, I need some help.");

  const location = useLocation();

  const isVisibleCurrency = ["/", "/Store", "/aboutus"].includes(
    location.pathname
  );

  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDocumentsDropdownOpen, setIsDocumentsDropdownOpen] = useState(false);

  // Function to close sidebar
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  // Function to handle logout
  const handleLogout = () => {
    signOut();
    navigate("/signin");
  };

  // Toggle sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Toggle dropdown for user menu
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Toggle documents dropdown
  const toggleDocumentsDropdown = () => {
    setIsDocumentsDropdownOpen(!isDocumentsDropdownOpen);
  };

  // Effect to add click event listener to close sidebar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isSidebarOpen && !event.target.closest(".sidebar")) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isSidebarOpen]);

  return (
    <div className="fixed w-full z-[99] top-0 bg-white shadow-md">
      <Notification/>
      <div className="bg-white sticky w-full top-0 z-50 drop-shadow-lg md:px-10 px-2 py-2 flex justify-between items-center text-black ">
        <div className="flex items-center">
          <button
            onClick={toggleSidebar}
            className="text-black px-1 rounded-lg"
          >
            {isSidebarOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
          <Link to="/" className=" items-center justify-center">
            <img
              src={TheGoldJar1080p}
              alt="csdlogo"
              className="cursor-pointer w-12 h-auto"
            />
          </Link>

          <Link to="/" className=" items-center justify-center">
            <img
              src={TheGoldJartitle}
              alt="csdlogo"
              className="cursor-pointer w-20 h-auto"
            />
          </Link>
        </div>

        <div className="flex items-center relative">
          <LiveGoldPrice />
          <div className="help-icon p-2 text-yellow-500 drop-shadow-xl">
            <a
              href={`https://wa.me/919519519581?text=${encodeURIComponent(
                "Hello, I need some help."
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaQuestionCircle size={24} />
              <a className="text-[10px] text-black font-black">HELP</a>
            </a>
          </div>
        </div>
      </div>

      <section>
        {/* Your other components and content */}
        {isVisibleCurrency && (
          <div className="fixed right-0 bottom-24  z-10">
            <UserCurrency />
          </div>
        )}
      </section>

      {/* Sidebar */}

      <div
        className={`fixed top-0 left-0 h-full bg-[#f7f7f7] shadow-lg transform transition-transform sidebar ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } w-64 z-[99] overflow-y-auto`}
      >
        <div className="flex flex-col h-full">
          <div className="p-4">
            <button
              onClick={toggleSidebar}
              className="text-black py-1 px-3 rounded-lg"
            >
              <i className="mdi mdi-close mdi-24px"></i>
            </button>
          </div>
          <div className="flex-grow p-4 pt-10">
            <Link
              to="/MarketPlace"
              onClick={closeSidebar}
              className="block text-black hover:text-yellow-500 py-1 px-3"
            >
              <i className="mdi mdi-gold mdi-24px mr-2"></i>
              Market
            </Link>
            <Link
              to="/Store"
              onClick={closeSidebar}
              className="block text-black hover:text-yellow-500 py-1 px-3"
            >
              <i className="mdi mdi-storefront-outline mdi-24px mr-2"></i>
              Store
            </Link>
            <Link
              to="/user/currency"
              onClick={closeSidebar}
              className="block text-black hover:text-yellow-500 py-1 px-3"
            >
              <i className="mdi mdi-information-outline mdi-24px mr-2"></i>
              How It Works?
            </Link>
            <Link
              to="/aboutus"
              onClick={closeSidebar}
              className="block text-black hover:text-yellow-500 py-1 px-3"
            >
              <i className="mdi mdi-frequently-asked-questions mdi-24px mr-2"></i>
              Aboutus
            </Link>
            <div className="relative">
              <div
                onClick={toggleDocumentsDropdown}
                className="text-black hover:text-yellow-500 py-1 px-3 cursor-pointer flex items-center"
              >
                <i className="mdi mdi-folder-outline mdi-24px mr-2"></i>
                Legal
                <FaChevronDown
                  className={`ml-auto ${
                    isDocumentsDropdownOpen ? "rotate-180" : ""
                  } transition-transform`}
                />
              </div>
              {isDocumentsDropdownOpen && (
                <div className="bg-white shadow-md absolute left-0 right-0 top-full mt-2">
                  <Link
                    to="/privacypolicy"
                    onClick={closeSidebar}
                    className="block text-black hover:text-yellow-500 py-1 px-3"
                  >
                    Privacy Policy
                  </Link>
                  <Link
                    to="/termsconditions"
                    onClick={closeSidebar}
                    className="block text-black hover:text-yellow-500 py-1 px-3"
                  >
                    Terms & Conditions
                  </Link>
                  <Link
                    to="/returnpolicy"
                    onClick={closeSidebar}
                    className="block text-black hover:text-yellow-500 py-1 px-3"
                  >
                    Cancellation & Refund
                  </Link>
                  <Link
                    to="/disclaimer"
                    onClick={closeSidebar}
                    className="block text-black hover:text-yellow-500 py-1 px-3"
                  >
                    Disclaimer
                  </Link>
                  <Link
                    to="/shippingdelivery"
                    onClick={closeSidebar}
                    className="block text-black hover:text-yellow-500 py-1 px-3"
                  >
                    Shipping & Delivery
                  </Link>
                </div>
              )}
            </div>
            <Link
              to="/contactus"
              onClick={closeSidebar}
              className="block text-black hover:text-yellow-500 py-1 px-3"
            >
              <i className="mdi mdi-phone-outline mdi-24px mr-2"></i>
              Contact
            </Link>
            {user ? (
              <>
                <div className="block text-black hover:text-yellow-500 py-1 px-3">
                  <span
                    className="block text-black hover:text-yellow-500"
                    onClick={toggleDropdown}
                  >
                    <i className="mdi mdi-account-circle-outline mdi-24px mr-2"></i>
                    Hi, {user.name}
                  </span>
                </div>
                <Link
                  to="/user/dashboard"
                  onClick={closeSidebar}
                  className="block text-black hover:text-yellow-500 py-1 px-3"
                >
                  <i className="mdi mdi-view-dashboard-outline mdi-24px mr-2"></i>
                  Account
                </Link>
                <button
                  onClick={handleLogout}
                  className="block text-black hover:text-yellow-500 py-1 px-3"
                >
                  <i className="mdi mdi-logout mdi-24px mr-2"></i>
                  Logout
                </button>
              </>
            ) : (
              <Link
                to="/signin"
                onClick={closeSidebar}
                className="block text-black hover:text-yellow-500 py-1 px-3"
              >
                <i className="mdi mdi-login mdi-24px mr-2"></i>
                LogIn
              </Link>
            )}

            <Link to="/" className="flex items-center justify-center pt-10">
              <img
                src={TheGoldJar1080p}
                alt="csdlogo"
                className="cursor-pointer w-8 h-8 sm:w-24 sm:h-24"
              />
            </Link>
            <p id="copyright" className="text-center text-xs text-black">
              <span className="font-bold">© Batchu Gold</span> <br />
              (CopyRightsReserved)
              <br />
              <a
                href="http://www.cyberspacedigital.in"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black hover:text-yellow-500"
              >
                Developed by CyberSpaceDigital
              </a>
            </p>
          </div>
        </div>
      </div>

      <BottomNavbar />
    </div>
  );
};

export default Navbar;
